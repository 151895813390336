.more-container {
    display: flex;
    justify-content: flex-end;
    .more-info {
        margin-bottom: 10px;
        width: 100px;
    }
}

.questions-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}