@import '../../styles/constants.scss';

#admin-tests {
    display: flex;
    flex-direction: column;

    .section-container {
        display: flex;
        height: 60vh;
        display: flex;

        .sections {
            overflow: auto;
            flex: 1;
        }
    }
    .add-container {
        display: flex;
        height: 23vh;
    }
}

.questions-container {
    display: flex;
    flex-direction: column;
    max-height: 50dvh;
    overflow-x: auto;
    padding: 0.2rem;

    .question-select {
        background-color: rgba($light, 0.5);
        border-radius: 4px;
        padding: 5px 10px;
        margin: 2px 0;
        font-weight: bold;
        border: none;
        outline: none;
        display: flex;
        text-align: start;
    
        &.is-selected {
            background-color: $accent;
            color: white;
        }
    }
}
