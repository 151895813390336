$background: #2a3846;
$light-background: #bdbdbd;

$light: #c9cacb;
$medium: #3a3b3d;
$dark: #12181f;

$accent: #202020;

$white: #FFFFFF;

$green: #4cae4c;
$red: #F47174;

$box-shadow: 2px 2px 1px -1px rgba(0,0,0,0.2), 2px 1px 1px 0px rgba(0,0,0,0.14), 2px 1px 3px 2px rgba(0,0,0,0.12);
$borders: #1e242c;