@import '../../../../styles/constants.scss';

.link-result {
    text-decoration: none;
}

.result-box-container {
    width: calc(100% - 50px);
    position: relative;
    overflow: hidden;
    margin-bottom: 5px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    background-color: $background;
    border: 2px solid  $borders;
    border-radius: .5rem;


    .result-box-title {
        text-transform: capitalize;
        font-weight: bold;
        font-size: 16px;
        color: $white;
    }
    .result-box-date {
        font-size: 12px;
        color: $white;
    }
    .result-content-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: $white;
    }
}

@media screen and (max-width: 768px) {
    .result-box-container {
        margin-bottom: 2px;
    }
}
