@import '../../../../../styles/constants.scss';

.select-test-type {
    display: flex;
    flex-direction: column;

    button {
        border-radius: .5rem;
        margin: 5px 0;
        background-color: #3c434c;
        border: 2px solid  $borders;
        outline: none;
        padding: 10px;
        color: white;
        font-weight: bold;
        transition: background-color linear .3s;

        &:hover {
            background-color: rgba($accent, 0.5);
        }

        &.selected {
            background-color: rgba($accent, 0.5);
        }
    }

    p {
        margin-bottom: 10px;
        color: $white;
    }
}