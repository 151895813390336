@import '../../../../styles/constants.scss';

.branch-button {
    background-color: transparent;
    border: none;
    padding: 0;

    .branch-item {
        background-color: $light;
        border-radius: 4px;
        margin: 5px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all linear .3s;
        cursor: pointer;
    
        &:hover {
            transform: scale(1.05);
        }
    
        img {
            width: 250px;
            height: 250px;
            object-fit: cover;
        }
    
        .branch-name {
            padding: 20px;
            display: flex;
            background-color: $medium;
            justify-content: center;
            align-items: center;
    
            p {
                color: white;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
    }
}