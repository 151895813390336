#SubjectViewer {

    .top-controls,
    .box,
    .page-visor {
        box-sizing: border-box;
    }

    .top-controls {
        top: 0px;
        position: sticky;
        padding: 10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        border-bottom: 1px solid rgb(230, 220, 220);
        box-shadow: 1px 1px 5px 5px #fbf5f5b3;
        background-color: #FFFF;
    }

    .top-control.pages{
        margin-left: 1%;
    }

    .top-control.zoom{
        display: flex;
        justify-self: center;
        align-items: center;
    }

    .top-control.fullscreen{
        display: flex;
        justify-self: end;
        margin-right: 1%;
        transition: transform 0.5s ease-in-out;
    }

    .top-control.fullscreen:hover{
        transform: scale(1.2,1.2);
    }

    .pdf-document.full-screen{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100vw;
        height: 100vh;
    }

    .pdf-document.full-screen .box{
        height: 100vh;
    }

    .box {
        margin: 0;
        padding: 0;
    }

    .box .box-content {
        max-height: 828px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: auto;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

    .box .box-content::-webkit-scrollbar {
        display: none;
    }

    .page-visor {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: fit-content;
        justify-self: center;

         * {
            width: 100%;
         }
    }
}