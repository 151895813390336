@import '../../../../styles/constants.scss';

.finished-modal {
    width: 1200px;

    .separate {
        height: 1px;
        width: 100%;
        background-color: $accent;
        margin: 10px 0;
    }

    .finished-test-loading {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        padding: 40px;

        .loading-text {
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
}