.crossfactory-document {
    position: relative;
    height: 99vh;

    .custom-options {
        position: absolute;
        bottom: 0;
        background-color: #444444;
        height: 23px;
        width: 100px;
        right: 0;
    }
}

#pdf-controls {
    z-index: 1000000;
}

#pdf-zoom-reset {
    display: none !important;
}

.no-download {
    #pdf-download {
        display: none !important;
    }
}