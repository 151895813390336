@import '../../../../styles/constants.scss';

.question {
    position: relative;

    .question-image {
        max-width: 150px;
    }

    .answers {
        margin-top: 0px;
        margin-bottom: 10px;

        .correct-answer {

            * {
                color: $green;
                font-weight: bold;
            } 
        }
        .wrong-answer {
            * {
                font-weight: bold;
                color: $red;
            }
        }
    }

    .question-title {
        text-transform: lowercase;
    }
    .question-title::first-letter {
        text-transform: uppercase;
    }

    .question-number {
        display: flex;
        margin: 10px 0;
        

        p {
            color: $white;
        }

        >div {
            margin-right: 5px;
        }
    }
    .answers {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
    }

    .info {
        margin-top: 20px;
        display: flex;
        align-items: center;
        background-color: rgba($accent, 0.8);
        padding: 5px;
        border-radius: 4px;

        p {
            font-size: 14px;
            margin-left: 5px;
            color: white;
            font-weight: bold;
        }
    }

    .test-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        .test-action {
            margin-left: 5px;
        }
    }

    .right-content {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .subject {
            background-color: $accent;
            padding: 5px 10px;
            border-radius: 5px;
            margin-left: 4px;
    
            p {
                color: $white;
                font-size: 14px;
                margin: 0;
                font-weight: bold;
            }
        }
    }
    .check-answer {
        margin-top: 10px;
    }

}
.rating-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    button {
        border: none;
        background-color: transparent;
    }
}

.is-correct {
    height: 100%;
    width: 8px;
    position: absolute;
    top: 0;
    left: -2px;

    &.correct {
        background-color: $green;
    }
    &.wrong {
        background-color: $red;
    }
}

@media screen and (max-width: 768px) {
    .question {
        .question-number {
            padding: 10px;
            margin: 0;

        }
        .right-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            top: 5px;
            width: 100%;
            position: initial;

            .subject {
                display: none;
            }

            .is-correct {
                width: 100%;
                margin-bottom: 10px;
                text-align: center;
            }
        }
        .test-actions {
            flex-direction: column-reverse;
            justify-content: center;
            margin-bottom: 10px;
            margin-top: 20px;
            
            button {
                margin-bottom: 5px;
            }
        }
    }
}