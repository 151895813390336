@import '../../styles/constants.scss';

#testpage {
    position: relative;
    .topbar-info {
        display: flex;
        position: fixed;
        z-index: 10;
        background-color: $background;
        width: calc(100% - 150px);
        bottom: 0;
        padding-bottom: 10px;
        justify-content: space-between;
        padding: 10px;
        align-items: center;

        .correct-test {
            margin-left: 5px;
        }

        button {
            margin-right: 25px;
        }
    }
    .questions {
        margin-top: 60px;
    }

    .screen-content {
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    #testpage {
        margin-bottom: 90px;
        .questions {
            margin-top: 0px;
        }
        
        .topbar-info {
            bottom: 0px;
            width: 100%;
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            top: auto;
            max-width: 100vw;
            background-color: $dark;
            z-index: 10;
            left: 0;

            .countdown {
                text-align: center;
            }

            * {
                width: 90%;
                color: white;
            }
            button {
                
                span {
                    color: $dark;
                    font-weight: 700;
                }
            }
        }
    }

}
.loading-state {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}