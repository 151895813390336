@import '../../../../styles/constants.scss';

.question-box-container {
    width: calc(100% - 40px);
    border-radius: 5px;
    background-color: $background;
    box-shadow: $box-shadow;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    padding: 10px 20px;

    .question-decorator {
        height: 100%;
        width: 5px;
        top: 0;
        left: 0;
        position: absolute;
    }

    .separator {
        background-color: $accent;
        width: 100%;
        height: 1px;
        margin: 10px 0;
    }
    .question-box {
        display: flex;

        .question-title {
            flex: 1;
            font-size: 12px;
            margin-right: 30px;
            color: $white;

            p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;  /* Number of lines displayed before it truncate */
                overflow: hidden;
            }
        }

        .answer {
            span {
                font-weight: bold;
                margin-right: 3px;
            }
        }
        .question-subject {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $accent;
            padding: 5px 10px;
            border-radius: 4px;
            height: 20px;
            .color {
                height: 10px;
                width: 10px;
                border-radius: 50%;
            }
            p {
                font-size: 13px;
                margin-left: 3px;
                font-weight: bold;
                color: white;
            }
        }

    }
}

@media screen and (max-width: 768px) {
    .question-box {
        flex-direction: column-reverse;
   
        .question-subject {
            background-color: transparent !important;
            justify-content: flex-start !important;
        }
    }
}