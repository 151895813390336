@import '../../../../styles/constants.scss';

.sidebar-user {
    display: flex;
    width: 100%;
    justify-content: center;
    .user {
        border-radius: 5px;
        height: 50px;
        width: 50px;
        background-color: $accent;
        display: flex;
        justify-content: center;
        align-items: center;
    
        p {
            color: white;
            font-size: 22px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
    .user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10px;

        .user-text {
            color: white;
            font-size: 14px;
            margin: 2px 0;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 768px) {
    .sidebar-user {
        display: none;
    }
}