@import '../../styles/constants.scss';

#start-test-page {
    .start-test-content {
        display: flex;
        flex-direction: column;

        .page-content {
            background-color: $dark;
            border-radius: 4px;
            margin: 10px 0;
            padding: 20px;

            * {
                color: white;
            }

            .title {
                font-size: 20px;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 20px;
                letter-spacing: 2px;
            }

            .st-description {
                margin-bottom: 20px;

            }
        }

        .box-actions {
            display: flex;
            justify-content: flex-end;
        }
    }
}