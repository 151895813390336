@import '../../styles/constants.scss';

.countdown {
    border-radius: 5px;
    width: auto;
    display: flex;

    p {
        color: $white;
        font-size: 13px;
        span {
            margin-left: 5px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 768px) {
    .countdown {
        border-radius: 5px;
        box-shadow: $box-shadow;
        width: auto;
        display: flex;
        padding: 10px 20px;
    
        p {
            color: $medium;
            font-size: 13px;
            span {
                margin-left: 5px;
                font-size: 14px;
                font-weight: bold;
                color: white;
            }
        }
    }
}