@import '../../../../styles/constants.scss';

.content-part {
    display: flex;
    flex-direction: column;
    max-height: 70vh;
    overflow: auto;
    padding: 20px 0;
}

.select-all-content {
    background-color: $green;
    border: none;
    margin-left: 10px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
}

.alloweance-modal {
    .user-title {
        span {
            font-weight: bold;
        }
    }

    .branch-title {
        font-size: 20px;
    }

    .button-subject {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        margin: 4px;
        border: 1px solid #FFFFFF50;
        border-radius: 7px;
        padding: 3px;

        .style-button {
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
        }

        .chevron {
            margin-right: 5px;
            height: 15px;
        }

        p {
            color: white;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1;
        }
    }

    .button-line {
        padding: 4px;
        margin: 8px 20px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #FFFFFF50;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .topic {
            display: flex;
        }
    }

    .line-content {
        margin-left: 40px;
        display: flex;
        justify-items: flex-start;
        align-items: center;
    }

    .eye {
        background-color: transparent;
        border: none;
        margin-right: 5px;
    }
}