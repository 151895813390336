@import '../styles/constants.scss';

button {
    cursor: pointer;
}

p, h1, h3, img {
    margin: 0;
}

p, h1, h2, h3, h4, h5, h6 {
    font-family: "Work Sans", "Noto Sans", sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}

.table-action {
    border: none;
    background-color: transparent;
}

.test-power {

    .cui-toolbar-button-right {
        display: none !important;
    }
}

.input-file {
    color: $white !important;
}

.ant-pagination-item {
    background-color: transparent !important;
}

.ant-select-selection-item, .ant-select-item-option-content {
    text-transform: capitalize;
}

.ant-picker-calendar-header {
    display: flex;
    flex-direction: row-reverse;

    >div {
        flex: 1;
        margin-right: 5px;
    }
}