#admin-page {
    .start-content {
        display: flex;
        flex-direction: column;
        padding: 0 10px;

        .admin-content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;

            .box {
                margin: 0;
                margin-bottom: 10px;
            }
        }

        .temary-and-subjects {
            display: flex;
            flex-direction: column;
            flex: 1;

            * {
                flex: 1;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #admin-page {
        .start-content {

            .admin-content {
                display: flex;
                flex-direction: column;
                gap: 0px;
            }



            .temary-and-subjects {
                display: flex;
                flex-direction: column;
                flex: 1;

                * {
                    flex: 1;
                }
            }
        }
    }
}