@import '../../styles/constants.scss';

.title-container {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 3;
    background-color: $background;
    padding: 20px;
    z-index: 10;

    .theme-toggler {
        background-color: $accent;
        padding: 10px;
        position: absolute;
        right: 150px;
    }

    &.dark-container {
        background-color: transparent;
    }
    .title-comp {
        color: $white;
        font-weight: 300;
        font-size: 38px;
        text-transform: uppercase;

        &.title-dark {
            color: white;
        }
    }

    .small-decorator {
        height: 5px;
        width: 30px;
        background-color: $accent;
        margin-left: 10px;
        margin-right: 10px;
    }

    .decorator {
        height: 5px;
        flex: 1;
        background-color: $accent;
        margin-left: 10px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 768px) {
    .title-container {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        width: calc(100% - 20px);
        z-index: 2;
        padding: 10px;
        margin-top: 30px;
        padding-top: 30px;
    
        &.dark-container {
            background-color: transparent;
        }
        .title-comp {
            font-weight: 400;
            font-size: 25px;
    
            &.title-dark {
                color: white;
            }
        }
    
        .small-decorator {
            height: 5px;
            width: 30px;
            background-color: $accent;
            margin-left: 10px;
            margin-right: 10px;
        }
    
        .decorator {
            height: 5px;
            flex: 1;
            background-color: $accent;
            margin-left: 10px;
            margin-right: 15px;
        }
    }
}