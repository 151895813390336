@import '../../../../styles/constants.scss';

.subject-result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .result-score {
        border: 10px solid transparent;
        border-radius: 100%;
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        

        &.danger {
            border-color: $red;
        }

        &.success {
            border-color: $green;
        }

        p {
            font-size: 35px;
            font-weight: bold;
            color: $white;
        }
    }
    .result-description {
        margin: 10px;
        font-size: 10px;
        color: $white;
    }
    .subject-name {
        font-size: 15px;
        color: #FFFFFF;
        font-weight: bold;
        text-transform: uppercase;
        margin: 20px 0px;
        margin-bottom: 40px;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .subject-result {
        flex-direction: column-reverse;
        height: auto;
        width: 100%;
        flex: 1;
        border-top: 1px solid $medium;

        .percentage {
            display: flex;
            width: 100%;
            margin-bottom: 10px;

            .result-score {
                height: 30px;
                width: 30px;
                border-width: 4px;
                margin-top: 0;
                display: flex;
                flex-direction: row;

                label {
                    margin-top: auto;
                }

                p {
                    font-size: 15px;
                }
            }
        }

        .subject-name {
            font-size: 15px;
            color: $white;
            font-weight: 500;
            text-transform: capitalize;
            width: 100%;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }
}