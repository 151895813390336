@import '../../../../styles/constants.scss';

.alloweance-modal {
    width: 90% !important;

    .check-subject {
        span {
            font-size: 20px;
            font-weight: bold;
        }
    }

    .topic-title {
        font-size: 17px;
        font-weight: bold;
    }

    .decorator {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        height: 1px;
        background-color: rgba(white, 0.5);
    }

    .title-select-all {
        display: flex;
        align-items: center;

        button {
            margin-top: 4px;
            background-color: transparent;
            color: white;
            border: none;
            margin-left: 10px;

            &:hover {
                color: $accent;
            }
        }
    }

    .subjects-part {
        display: flex;
        flex-direction: column;
    }
}