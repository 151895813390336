@import '../../styles/constants.scss';

#login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;

    main {
        display: flex;
        flex-direction: column;
        width: 100vw;
        max-width: 400px;
        justify-content: center;
        align-items: center;
        height: 90vh;

        .error-message {
            color: $red;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 700;
        }

        img {
            width: 200px;
            margin-right: 10px;
        }

        .forgot-remember {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 20px;

            .remember {
                color: white;
            }

            .forgot {
                color: white;
                margin-right: -10px;
            }
        }

        .login-button {
            width: 100%;
        }

        .login-input {
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    #login-page {    
        main {
            margin-top: -100px;
            width: 100%;

            img {
                width: 150px;
                margin-right: 10px;
            }
    
            .login-button {
                width: 100%;
            }
    
            .login-input {
                margin-bottom: 20px;
            }
        }
    }
}