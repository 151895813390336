#loading {
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;

        img {
            width: 300px;
        }

        p {
            color: white;
            margin-top: 10px;
        }
    }
}