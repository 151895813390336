.forum-answer {
    border-top: 1px solid rgba(white, 0.3);
    margin: 10px 0;
    padding: 10px;

    span {
        font-size: 14px;
        font-weight: bold;
        margin-right: 3px;
    }

    p {
        font-size: 14px;
        color: white;
    }
    
    label {
        font-size: 11px;
        color: grey;
    }
}