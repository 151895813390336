@import '../../../../styles/app.scss';

.forum-question {
    position: relative;
    .remove-action {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
    }


    .avatar {
        border-radius: 50%;
        height: 20px;
        width: 20px;
        object-fit: cover;
    }

    .user {
        display: flex;
        align-items: center;

        p {
            color: $white;
            margin-left: 10px;
            font-size: 12px;
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        margin-top: 20px;

        * {
            color: white;
        }
    }

    button {
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        background-color: transparent;
    }

    textarea {
        margin-top: 10px;
        width: 90%;
    }

    .textarea-actions {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
}
