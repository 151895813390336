@import '../../../../styles/constants.scss';

.next-session {
    background-color: $background;
    border-radius: .5rem;
    border: 2px solid $borders;
    padding: 8px;

    .line {
        margin: 10px 0;
        background-color: $medium;
        width: 100%;
        height: 2px;
    }

    .title-session {
        font-size: 16px;
        font-weight: 600;
    }

    .teacher {
        font-size: 13px;
    }

    .session-date, .school {
        display: flex;
        align-items: center;
        p {
            text-transform: capitalize;
            font-size: 14px;
            margin-left: 5px;
        }
    }

    .join {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            background-color: $accent;
            border: none;
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: background-color linear .3s;

            &:hover {
                background-color: rgba($accent, 0.7);
            }
        }
    }

    .school {
        margin-top: 10px;
    }

    * {
        color: white;
    }
    
}

@media screen and (max-width: 768px) {
    
}