@import '../../styles/constants.scss';

#dashboard {
    display: flex;
    flex-direction: column;

    .section-container {
        display: flex;
        height: 43vh;
        display: flex;

        .sections {
            overflow: auto;
            flex: 1;
        }

        .calendar {
            max-width: 400px;
            padding-bottom: 10px;

            .calendar-comp {
                height: 100%;
                background-color: $dark;

                .ant-picker-panel {
                    background-color: $dark;
                }
            }
        }
    }
}

.cale-root {
    background-color: $dark;
}

.calendar-mark {
    border: 4px solid #1568db;
    background-color: transparent;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 90%;
    height: 90%;
    border-radius: 4px;
}

@media screen and (max-width: 768px) {
    #dashboard {
       .section-container {
            flex-direction: column;
            height: auto;
            .box {
                max-height: 500px;
            }
        }
        .calendar {
            max-width: none !important;
            padding: 0 !important;
    
            .calendar-comp {
                height: 100%;
                background-color: $dark;
    
                .ant-picker-panel {
                    background-color: $dark;
                }
            }
        }
    }

}

