@import '../../../../styles/constants.scss';

.test-result {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: rgba($light, 0.2);
    padding: 10px 15px;
    border-radius: 8px;
    margin: 5px 0;

    p {
        margin: 0;
        font-weight: bold;
    }

    .result {
        align-items: center;
        display: flex;
        font-weight: bold;
        p {

            margin-right: 5px;

            &.success {
                color: green
            }

            &.failed {
                color: red;
            }
        }
    }
}