@import '../../styles/constants.scss';

.chrome {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: $dark;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        color: white;
        text-align: center;
    }

    a {
        color: white;
        text-decoration: none;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        margin-top: 10px;
        background-color: $green;
        padding: 10px 15px;
        border-radius: 4px;
    }
}


@media screen and (max-width: 768px) {
    .chrome {
        bottom: 40px;
        left: 0;
        right: 0

        p {
            text-align: center;
        }
    }

}