#start-test {
    .saved-questions {
        display: flex;

        >div {
            height: 76vh;
        }
    }

}

.saved-questions-box {
    flex-direction: column;
}

@media screen and (max-width: 768px) {
    #start-test {
        .saved-questions {
            flex-direction: column;

            .box {
                max-height: 500px !important;
            }
        }
    }
}