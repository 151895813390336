#reset-password-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .os-input-container,
  input {
    width: 90%;
    max-width: 300px;
    margin-top: 8px;
  }

  input {
    width: 100%;
  }

  button {
    margin-top: 20px;
  }
}