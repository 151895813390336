@import '../../styles/constants.scss';

.box {
    margin: 8px;
    margin-top: 0;
    padding: 0px 5px;
    padding-left: 10px;
    position: relative;
    overflow: auto;
    background-color: $dark;
    border-radius: .5rem;
    border: 2px solid $borders;
    display: flex;
    flex-direction: column;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    padding: 20px 25px;

    .scroller {
        display: flex;
        overflow: auto;
        flex-direction: column;
        flex: 1;
    }

    .box-title {
        background-color: $dark;
        display: flex;
        align-items: center;
        position: sticky;
        top: -2px;
        z-index: 1;
        color: $dark;
        line-height: 1.14286;
        font-weight: 500;
        letter-spacing: .007em;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;

        h3 {
            color: $white;
            font-weight: 500;
            font-size: 20px;
            text-transform: uppercase;
        }

        .collapser {
            border: none;
            outline: none;
            background-color: transparent;
            padding: 0;
            display: none;
            margin-right: 5px;
            margin-top: 4px;


            * {
                transition: all linear 0.3s;
            }

            .collapsed {
                transform: rotate(90deg)
            }
        }
    }

    .box-description {
        display: flex;
        align-items: center;
        color: $white;
        margin-top: 10px;

        p {
            font-weight: 400;
            font-size: 13px;
            margin: 0;
        }
    }

    .box-content {
        margin-bottom: 10px;
        flex: 1;
        display: flex;
    }

    .box-actions {
        margin-top: 15px;

        .margined {
            margin-left: 2px;
        }
    }
}

@media screen and (max-width: 768px) {
    .content-collapsed {
        display: none !important;
    }

    .box {

        .box-title {
            margin: 0;

            h3 {
                font-weight: 400;
                font-size: 16px;
            }

            .collapser {
                display: block;
            }
        }

        .box-actions {
            display: flex;
            flex-direction: column;

            .actions {
                position: initial;
                left: 0;
                width: auto;
                display: flex;
                justify-content: flex-start;
                margin: 20px 0;
            }
        }
    }
}