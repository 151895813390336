#profile {
    .profile-edit {
        width: 100%;
        display: flex;

        >div {
            height: 80vh;
        }

        .box-actions {
            flex-direction: column;
            align-items: flex-start;
        }

        >.box>.box-content {
            display: flex;
            flex-direction: column;
            gap: 5%;
        }
    }

    .input-file::before{
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' focusable='false' data-icon='upload' width='1em' height='1em' fill='currentColor' aria-hidden='true'%3E%3Cpath d='M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z'%3E%3C/path%3E%3C/svg%3E");
        display: inline-block;
        cursor: pointer;
    }

    .input-file::-webkit-file-upload-button {
        display: none;
    }

    .input-file::-moz-focus-inner {
        display: none;
    }

    .input-file::-ms-browse {
        display: none;
    }

}