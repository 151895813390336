@import '../../styles/constants.scss';

.input {
    margin-bottom: 10px;

    &.check {
        display: flex;
        align-items: center;

        .input-label {
            margin-left: 5px;
            margin-top: 2px;
        }
    }

    .file-uploader {
        display: flex;

        p {
            margin-left: 10px;
        }
    }

    .input-label {
        color: $white;
        font-size: 12px;
    }

    .hidden-input-file {
        position: relative;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .file-loading-bar {
        box-sizing: border-box;
        height: 3.9px;
        background-color: rgba(1, 97, 14, 0.719);
        transition: width ease-in-out;
        border-radius: 3px;
        margin: 1% 0%;
    }
}