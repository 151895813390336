@import '../../styles/constants.scss';

.screen {
    background-color: $background;
    min-height: 100vh;

    .actions {
        position: absolute;
        right: 30px;
        bottom: 30px;
        display: flex;
        justify-content: flex-end;
    }

    &.dark {
        background-color: $dark;
    }

    .content {
        min-height: calc(100vh - 60px);
        padding: 30px;

        &.with-sidebar {
            margin-left: 60px;
        }

        .title {
            color: $dark;
            font-size: 40px;
            font-weight: bold;
        }

        .screen-content {
            display: flex;
            flex-direction: column;
            margin-top: 70px;
        }

    }
    .csoapps {
        position: fixed;
        bottom: 0px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        background-color: $background;
        padding: 10px 0;

        p {
            margin-right: 2%;
            color: $white;
            font-size: 12px;
            letter-spacing: 1;

            &:hover {
                a {
                    color: $accent;
                }
            }

            a, span {
                color: $white;
                transition: all linear .3s;
                font-weight: 800;
                text-decoration: none;
            }
        }

        &.is-dark {
            background-color: transparent;
            p, a, span {
                color: $light;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .screen {
        min-height: 100vh;
        max-width: 100vw;

        .ant-table-wrapper {
            overflow: auto;
            /* td {
                background-color: white;
            } */
        }

        &.dark {
            height: 100vh;
        }

        .content {
            padding: 3px;
            min-height: auto;
            &.with-sidebar {
                margin-left: 0px;
                margin-top: 0px;
                padding-top: 120px;
            }

            .screen-content {
                margin-top: 0;
                margin-bottom: 30px;
            }
        }
        .csoapps {
            position: fixed;
            width: 100%;
            display: flex;
            justify-content: center;
            right: 0;
            z-index: 100000;
    
            p {
                text-align: center;
            }
        }
    }
}