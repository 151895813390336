@import '../../styles/constants.scss';

#online-sessions {
    .online-session-container {
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .empty-state {
        height: 80vh;
    }

    .online-session {
        background-color: $dark;
        border-radius: 4px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        p {
            color: white;
            text-transform: uppercase;
        }

        a, button {
            background-color: $green;
            border-radius: 4px;
            padding: 10px 20px;
            text-decoration: none;
            color: white;
            font-weight: bold;
        }

        button {
            background-color: $red;
            padding: 10px;
            height: 40px;
            margin-left: 5px;
        }

        .title {
            font-size: 20px;
        }
        .date {
            font-size: 15px;
        }

        .buttons {
            display: flex;
            align-items: center;
        }
    }
}
