@import '../../styles/constants.scss';

.subject-header {
    display: flex;
    justify-content: space-between;
}

.add-folder {
    background-color: $green;
    border: none;
    margin-left: 10px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    padding: 5px 10px;

}

.folders {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $background;
    padding: 5px 10px;
    margin: 5px 0;
    border: none;
    border-radius: 4px;
    width: 100%;

    button {
        background-color: transparent;
        border: none;
        outline: none;
        color: white;
        text-transform: uppercase;

    }

    * {
        color: white;
        display: flex;
        align-items: center;
    }

    p {
        margin-left: 5px;
    }
}

.add-content-container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 10px;

    .add-content {
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        background-color: $accent;
        color: white;
        font-weight: 700;
        width: 150px;
        margin-right: 10px;

    }
}

.fullscreen-doc {
    background-color: #fff;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    padding: 0;
    margin: 0 0 0 5px;
    text-align: center;
    font-size: 18px;
    border: 0;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 35px;
    opacity: 1;
    pointer-events: all;
    box-shadow: 2px 2px 3px #00000033;
    margin: 5px;
}

.subjects-content {
    padding: 5px;
}

.download-content {
    margin-left: 10px;
    color: $green;
    font-weight: bold;
    font-size: larger;
}

.content-line {
    display: flex;
    justify-content: space-between;
    background-color: $background;
    padding: 5px 10px;
    margin: 5px 0;
    align-items: center;
    border-radius: 4px;

    a {
        color: white;
        text-transform: uppercase;

        &:hover {
            font-weight: bold;
        }
    }

    button {
        background-color: transparent;
        border: none;
        outline: none;
        color: white;
        text-transform: uppercase;
    }

    .line-actions {
        margin-left: 20px;
    }
}