@import '../../styles/constants.scss';

.sidebar-class {
    transition: all linear .3s;
    width: 50px;

    &.open {
        width: 250px;
    }
}

#sidebar {
    background-color: $dark;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 5px 5px 0px;
    padding: 10px 15px;
    box-shadow: $box-shadow;
    z-index: 11;

    .sidebar-img {
        max-width: 100px;
        width: 100%;
        margin: 20px;
    }

    .arrow {
        transition: all linear .3s;

        &.open {
            transform: rotate(180deg);
        }
    }

    .logo {
        margin-top: 30px;
        width: 50px;
    }

    .toggler {
        border: none;
        background-color: $medium;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 20px;
        right: -10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
    }

    .navbar-toggler {
        display: none;
    }

    .full-sections {   
        height: 100%;
        display: flex;
        flex-direction: column;  
        overflow: auto;
        width: 100%;
        scrollbar-color: transparent transparent;
        scrollbar-width: thin;

        ::-webkit-scrollbar {
            display: none;
        }
        
        .sections {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex: 1;
            justify-content: center;
        }
        .bottom-sections {
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

}

@media screen and (max-width: 768px) {
    #sidebar {
        flex-direction: row;
        height: 20px;
        width: 100%;
        z-index: 1000;
        border-radius: 0;

        .sidebar-img {
            max-width: none;
            width: 40px;
            position: absolute;
            left: calc(50% - 30px);
            margin: 0;
        }

        .full-sections {
            background-color: $dark;
            position: fixed;
            top: 39px;
            left: 0;
            width: 100%;
            display: none;
            border-radius: 0 0 10px 10px;

            &.navbar-open {
                display: block;
                height: auto;
            }
        }

        .toggler {
            display: none;
        }

        .navbar-toggler {
            display: block;
            background-color: transparent;
            border: none;
        }
    }
}