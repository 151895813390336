@import '../../../../../../styles/constants.scss';

.margined-own-tests {
    margin-bottom: 30px;
}
.tests-container {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow: auto;


    button {
        margin: 5px;
        border: none;
        border-radius: 5px;
        background-color: $background;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        font-weight: 600;
        color: white;
        text-transform: capitalize;

        &:hover {
            background-color: rgba($accent, 0.5);
        }

        &.selected-test {
            background-color: $accent;
            color: white;
        }
    }
}