#loading {
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100vh;

        img {
            max-width: 150px;
            margin-bottom: 30px;
            width: 90%;
        }

        .new-device-input {
            width: 300px;
            margin-top: 10px;
        }

    }
    p {
      color: white;
      width: 90%;
      max-width: 500px;
      text-align: center;
      margin-top: 20px;
    }
}