@import '../../styles/constants.scss';

.empty-state {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        color: $white;
        margin-top: 20px;
    }
}