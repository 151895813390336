@import '../../../../styles/constants.scss';

.generate-test-modal {
    .params {
        display: flex;
        flex-direction: column;
    }
    .select-subjects {
        background-color: rgba($accent, 0.2);
        padding: 5px 10px;
        border-radius: 4px;
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
    }
}