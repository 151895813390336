@import '../../../../styles/constants.scss';

.sidebar-button-link {
    text-decoration: none;
}

.sidebar-button {
    width: calc(100% - 10px);
    min-width: 50px;
    height: 50px;
    border: none;
    border-radius: 5px;
    padding: 5px;
    transition: background-color linear .3s;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-left: 7px;
    }

    &.open {
        justify-content: flex-start;

        p {
            color: white;
            width: 100%;
        }
    }


    &:hover {
        background-color: $accent;
    }

    p {
        color: transparent;  
        margin-left: 8px; 
        font-size: 17px;
        font-weight: 500;
        transition: color linear .2s;
        width: 0;
        text-align: start;
    }
}

@media screen and (max-width: 768px) {
    .sidebar-button {
        margin-left: 10px;
        height: auto;
        margin-top: 10px;
    
        svg {
            margin-left: 0px;
        }
    
        &:hover {
            background-color: $accent;
        }
    
        p {
            color: white;  
            width: 100%;
            font-size: 14px;
        }
    }
}
